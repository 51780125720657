import React from "react"
import { Link as GatsbyLink } from "gatsby"

interface CustomLinkProps {
  href?: string
}

const CustomLink: React.FC<CustomLinkProps> = ({ href, children }) => {
  if (href.startsWith("/")) {
    return <GatsbyLink to={href}>{children}</GatsbyLink>
  }

  const onPage = href.startsWith("#")

  return (
    <a
      href={href}
      target={onPage ? null : "_blank"}
      rel={onPage ? null : "noopener noreferrer"}
    >
      {children}
    </a>
  )
}
export default CustomLink
