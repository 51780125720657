import React from "react"
import { CurrentPriceContainer, CurrentPriceLink } from "../../elements"

interface CheckPriceProps {
  href?: string
}

const CheckPrice: React.FC<CheckPriceProps> = ({ href }) => {
  return (
    <CurrentPriceContainer>
      <CurrentPriceLink href={href} target="_blank">
        CHECK CURRENT PRICE
      </CurrentPriceLink>
    </CurrentPriceContainer>
  )
}
export default CheckPrice
