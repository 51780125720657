import styled from "@emotion/styled"

export const ContactForm = styled.form`
  padding: 20px 0 16px 0;
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
  justify-content: center;
  align-content: center;
  text-align: center;
  @media (max-width: 600px) {
    width: 100%;
  }

  & textarea {
    padding: 12px 10px;
    border: 1px solid black;
    font-size: 1rem;
    border-radius: 2px;
    margin: 10px;
    max-height: 176px;
    min-height: 176px;
    max-width: 500px;
    @media (max-width: 600px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  & input {
    font-size: 1rem;
    border-radius: 2px;
    margin: 10px;
    @media (max-width: 600px) {
      margin-left: 0px;
      margin-right: 0px;
    }
    &[type="email"],
    &[type="text"] {
      padding: 12px 10px;
      border: 1px solid black;
    }
    &[type="submit"] {
      -webkit-appearance: none;
      padding: 12px 24px;
      border: none;
      font-size: 1.1rem;
      font-weight: 500;
      background-color: black;
      color: white;
      cursor: pointer;
      margin: 10px;
      @media (max-width: 600px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
`
