import styled from "@emotion/styled"

export const BlogContainer = styled.div`
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const BlogHeading = styled.div`
  padding: 50px 0px;
  width: 100%;
  text-align: center;
  background-color: #d2e3fc;
  @media (max-width: 600px) {
    padding-top: 100px;
  }
`

export const BlogPosts = styled.div`
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
