import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import {
  ArticlesGridContainer,
  DisplayFlexColumn,
  MainContainer,
  PageSectionNormal,
  PageSectionNormalHeader,
  SectionSpacer,
} from "../../elements"
import { ArticleCardComponent } from "../ArticleCard"

interface RecentArticlesProps {}

const RecentArticles: React.FC<RecentArticlesProps> = ({}) => {
  const { articles } = useStaticQuery(graphql`
    query {
      articles: allMdx(
        limit: 6
        filter: {
          frontmatter: { published: { eq: true }, type: { eq: "blog" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            slug
            timeToRead
            frontmatter {
              description
              title
              type
              date(formatString: "MMMM DD, YYYY")
              featureImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <PageSectionNormal>
      <MainContainer>
        <DisplayFlexColumn>
          <PageSectionNormalHeader>
            <h2>Recent Articles</h2>
          </PageSectionNormalHeader>
          <SectionSpacer marginTop="1em" />
          <ArticlesGridContainer>
            {articles.edges.map(article => (
              <ArticleCardComponent
                type={article.node.frontmatter.type}
                key={article.node.id}
                title={article.node.frontmatter.title}
                description={article.node.frontmatter.description}
                updatedDate={article.node.frontmatter.date}
                slug={article.node.slug}
                timeToRead={article.node.timeToRead}
                fixed={article.node.frontmatter.featureImage.childImageSharp}
              />
            ))}
          </ArticlesGridContainer>
        </DisplayFlexColumn>
      </MainContainer>
    </PageSectionNormal>
  )
}
export default RecentArticles
