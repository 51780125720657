export const pluckTags = edges =>
  Object.keys(
    edges.reduce((acc, value) => {
      value.node.frontmatter.tags.forEach(tag => {
        if (!acc[tag]) {
          acc[tag] = tag
        }
      })

      return acc
    }, {})
  )
