import styled from "@emotion/styled"

export const PageHeaders = styled.div`
  background-color: #d01414;
  padding: 5.3em 0em 1.6em 0em;
  position: relative;
`

export const PageHeading = styled.div`
  text-align: center;

  @media (min-width: 600px) {
    width: 95%;
    margin: 0 auto;
  }
  & h2 {
    color: #fff;
    font-family: ${props => props.theme.fonts.article};
  }
`

export const PageSectionNormal = styled.div`
  background-color: #fff;
  min-height: 10px;
  padding: 3em 0em;
`

export const PageSectionGrey = styled.div`
  background-color: #e8eaed;
  min-height: 10px;
  padding: 3em 0em;
`

export const PageSectionNormalHeader = styled.div`
  font-family: ${props => props.theme.fonts.article};
  & a {
    color: #d01414;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const PageSectionPronounced = styled.div`
  background-color: #fce8e6;
  min-height: 10px;
  padding: 3em 0em;
`

export const PageSectionPronouncedHeader = styled.div`
  font-family: ${props => props.theme.fonts.article};
`
