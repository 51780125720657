import styled from "@emotion/styled"

export const ArticlesGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
export const ArticlesItem = styled.div`
  margin-top: 20px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  border-style: solid;
  border-width: 0px;
  border-color: #818a91;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
  &:hover {
    transition: transform 0.3s ease 0s;
    transform: translateY(-2px);
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
  }
`

export const ArticleElement = styled.article``

export const ArticleCard = styled.div`
  position: relative;
  overflow: hidden;
  height: 0px;
  padding-top: 90%;
`
export const ArticleCardContent = styled.div`
  padding: 15px 20px;
  margin-bottom: 10px;

  & h3 {
    font-size: 1.05rem;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #000;

    &:hover {
      text-decoration: underline;
      color: #d01414;
    }
  }
`
export const ArticleCardMetadata = styled.div`
  display: block;
  color: rgb(187, 187, 187);

  &::after {
    display: block;
    content: "";
    clear: both;
  }
`
export const ArticleCardMetadataText = styled.span`
  display: block;
  font-size: 0.53em;
  text-transform: uppercase;
  color: #d01414;
  font-weight: bold;
  margin-bottom: 10px;
  padding-top: 10px;
  &.floatRight {
    float: right;
  }
  &.floatLeft {
    float: left;
  }
`
export const ArticleCardMetaDescription = styled.div`
  display: inline-block;
  margin-bottom: 23px;
  font-size: 0.9em;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
  &:hover {
    text-decoration: underline;
    color: #d01414;
  }
`
