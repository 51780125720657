import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import {
  HeroElement,
  HeroElementCover,
  HeroFormContainer,
  HeroFormInner,
  HeroFormInput,
  HeroHeading,
  HeroHeading2,
  HeroImageWrapper,
  HeroInner,
  HeroInnerColumn,
  HeroInnerHeading,
  HeroInnerRow,
} from "../../elements"
import { CustomImage } from "../Image"

interface HeroProps {}

const Hero: React.FC<HeroProps> = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "shoes_hero.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <HeroElement>
      <HeroInner>
        <HeroInnerRow>
          <HeroInnerColumn>
            <HeroInnerHeading>
              <div>
                <HeroHeading>Best For Your Feet</HeroHeading>
              </div>
            </HeroInnerHeading>
            <HeroInnerHeading>
              <div>
                <HeroHeading2>
                  Best Reviews and Comparisons for shoes and footwear
                  accessories.
                </HeroHeading2>
              </div>
            </HeroInnerHeading>
            <HeroElementCover>
              <HeroFormContainer>
                <form>
                  <HeroFormInner>
                    <HeroFormInput type="search" placeholder="search reviews" />
                  </HeroFormInner>
                </form>
              </HeroFormContainer>
            </HeroElementCover>
          </HeroInnerColumn>
          <HeroInnerColumn>
            <div style={{ padding: "10px", width: "100%" }}>
              <HeroImageWrapper>
                <CustomImage
                  image={data.imageSharp}
                  alt="Best Reviews and Comparisons for shoes and footwear
                  accessories."
                />
              </HeroImageWrapper>
            </div>
          </HeroInnerColumn>
        </HeroInnerRow>
      </HeroInner>
    </HeroElement>
  )
}
export default Hero
