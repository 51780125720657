import styled from "@emotion/styled"

export const PostContainer = styled.div`
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 600px) {
    padding-bottom: 50px;
  }
`

export const PostContainerInner = styled.div`
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const PostHeading = styled.div`
  padding: 50px 0px;
  width: 100%;
  text-align: center;
  background-color: #d2e3fc;
  @media (max-width: 600px) {
    padding-top: 70px;
  }
`

export const PostHeader = styled.div``

export const PostMeta = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
export const PostAuthor = styled.div`
  padding: 0px 15px;
`

export const PostDate = styled.div`
  padding: 0px 15px;
`

export const BlogPost = styled.div``

export const BlogPostContent = styled.div`
  text-align: left;
  padding: 30px 0px;
`
