import React from "react"
import {
  DisplayFlexColumn,
  FeaturedBy,
  FourColumnGrid,
  MainContainer,
  PageSectionPronounced,
  PageSectionPronouncedHeader,
  SectionSpacer,
} from "../../elements"
import BusinessInsider from "./BusinessInsider"
import BuzzFeed from "./BuzzFeed"
import RunRepeat from "./RunRepeat"
import WalkJogRun from "./WalkJogRun"

interface FeaturedProps {}

const Featured: React.FC<FeaturedProps> = ({}) => {
  return (
    <PageSectionPronounced>
      <MainContainer>
        <DisplayFlexColumn>
          <PageSectionPronouncedHeader>
            <h2>Featured on</h2>
          </PageSectionPronouncedHeader>
          <SectionSpacer marginTop="5em" />
          <FourColumnGrid>
            <FeaturedBy>
              <RunRepeat />
            </FeaturedBy>
            <FeaturedBy>
              <BusinessInsider />
            </FeaturedBy>
            <FeaturedBy>
              <BuzzFeed />
            </FeaturedBy>
            <FeaturedBy>
              <WalkJogRun />
            </FeaturedBy>
          </FourColumnGrid>
        </DisplayFlexColumn>
      </MainContainer>
    </PageSectionPronounced>
    // <FeaturedElement>
    //   <FeaturedElementInner>
    //     <FeaturedElementHeader>
    //       <h1>Featured on</h1>
    //     </FeaturedElementHeader>
    //     <FeaturedElementCompanies>
    //       <FeaturedElementCompany>
    //         <FeaturedElementCompanyInner>
    //           <RunRepeat />
    //         </FeaturedElementCompanyInner>
    //       </FeaturedElementCompany>
    //       <FeaturedElementCompany>
    //         <FeaturedElementCompanyInner>
    //           <BusinessInsider />
    //         </FeaturedElementCompanyInner>
    //       </FeaturedElementCompany>
    //       <FeaturedElementCompany>
    //         <FeaturedElementCompanyInner>
    //           <BuzzFeed />
    //         </FeaturedElementCompanyInner>
    //       </FeaturedElementCompany>
    //       <FeaturedElementCompany>
    //         <FeaturedElementCompanyInner>
    //           <WalkJogRun />
    //         </FeaturedElementCompanyInner>
    //       </FeaturedElementCompany>
    //     </FeaturedElementCompanies>
    //   </FeaturedElementInner>
    // </FeaturedElement>
  )
}
export default Featured
