import { css, Global, useTheme } from "@emotion/react"
import { MDXProvider } from "@mdx-js/react"
import React from "react"
import {
  CheckPrice,
  Cons,
  CustomLink,
  Footer,
  Header,
  Pros,
  ProsCons,
  SelectionListTopSelection,
  SelectionsList,
  Specifications,
  TopSelection,
} from "../components"
import { ThemeContext } from "../context"
import {
  SelectionsListItem,
  SelectionsListItemButton,
  SelectionsListItemImage,
  SelectionsListItemLabel,
  SelectionsListItemSpecs,
} from "../elements"
import { Seo } from "../Seo"

const components = {
  CheckPrice,
  TopSelection,
  Specifications,
  Pros,
  Cons,
  ProsCons,
  SelectionsList,
  SelectionsListItem,
  SelectionsListItemLabel,
  SelectionsListItemImage,
  SelectionsListItemSpecs,
  SelectionsListItemButton,
  SelectionListTopSelection,
  a: CustomLink,
}

interface LayoutProps {
  title: string
  description?: string
  lang?: string
  image?: any
  meta?: []
  pathname?: string
  keywords?: []
  schemaMarkup?: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  description,
  lang,
  meta,
  image,
  title,
  pathname,
  keywords,
  schemaMarkup,
}) => {
  const theme = useTheme()
  const globalStyles = (
    <Global
      styles={css`
        *,
        *:before,
        *:after {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }
        ::selection {
          background: #ffb7b7;
        }
        ::-moz-selection {
          background: #ffb7b7;
        }
        html {
          font-size: 19px;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        ul {
          list-style-type: none;
          margin-block-start: 0;
          margin-block-end: 0;
        }
        body {
          background-color: ${theme.colors.contentBackgroundColor};
          color: ${theme.colors.contentTextColor};
          -webkit-text-size-adjust: 100%;
          font-family: ${theme.fonts.main};
        }
        h1 {
          font-size: 1.8rem;
          margin-bottom: 10px;
          color: ${theme.colors.headingTextColor};
        }
        h2 {
          font-size: 1.6rem;
          margin-bottom: 10px;
          color: ${theme.colors.headingTextColor};
        }
        h3 {
          color: ${theme.colors.headingTextColor};
        }
      `}
    />
  )
  return (
    <ThemeContext.Consumer>
      {(theme: {
        dark: boolean
        loadTheme: object
        toggleTheme: () => void
      }) => (
        <MDXProvider components={components}>
          {globalStyles}
          <Seo
            title={title}
            description={description}
            pathname={pathname}
            image={image}
            meta={meta}
            keywords={keywords}
            lang={lang}
            schemaMarkup={schemaMarkup}
          />
          <Header theme={theme} />
          {children}
          <Footer />
        </MDXProvider>
      )}
    </ThemeContext.Consumer>
  )
}
export default Layout
