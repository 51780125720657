import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { CustomLink } from ".."
import {
  DisplayFlexColumn,
  FooterCopyRight,
  FooterDisclaimer,
  FooterLogo,
  FooterNavLinks,
  FooterSocialLinks,
  MainContainer,
  PageFooter,
  PageSectionGrey,
} from "../../elements"
import { Logo } from "../Header"
import { Facebook, Instagram, Pinterest, TikTok, Twitter } from "./SocialLinks"

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagram
          pinterest
          facebook
          tiktok
        }
      }
    }
  `)

  return (
    <PageFooter>
      <PageSectionGrey>
        <MainContainer>
          <DisplayFlexColumn>
            <FooterSocialLinks>
              <CustomLink href={site.siteMetadata.facebook}>
                <Facebook />
              </CustomLink>
              <CustomLink href={site.siteMetadata.instagram}>
                <Instagram />
              </CustomLink>
              <CustomLink href={site.siteMetadata.tiktok}>
                <TikTok />
              </CustomLink>
              <CustomLink href={site.siteMetadata.pinterest}>
                <Pinterest />
              </CustomLink>
              <CustomLink href={site.siteMetadata.facebook}>
                <Twitter />
              </CustomLink>
            </FooterSocialLinks>
            <FooterLogo>
              <Logo />
            </FooterLogo>
            <FooterNavLinks>
              <CustomLink href="/">Home</CustomLink>
              <CustomLink href="/about-us/">About Us</CustomLink>
              <CustomLink href="/contact/">Contact</CustomLink>
              <CustomLink href="/privacy/">Privacy</CustomLink>
              <CustomLink href="/disclaimer/">Disclaimer</CustomLink>
              <CustomLink href="/sitemap/sitemap-0.xml">Sitemap</CustomLink>
            </FooterNavLinks>
            <FooterCopyRight>
              <p>© 2020 Best For Your Feet LLC</p>
            </FooterCopyRight>
            <FooterDisclaimer>
              <p>
                <strong>Bestforyourfeet.com</strong> is a participant in the
                Amazon Services LLC Associates Program. An affiliate advertising
                program designed to provide a means for us to earn fees by
                linking to Amazon.com and affiliated sites.
              </p>
            </FooterDisclaimer>
          </DisplayFlexColumn>
        </MainContainer>
      </PageSectionGrey>
    </PageFooter>

    // <FooterElement>
    //   <ContainerGlobal>
    //     <FooterElementRow>
    //       <FooterSocialLinks>

    //       </FooterSocialLinks>

    //       <div
    //         style={{
    //
    //         }}
    //       >

    //       </div>
    //     </FooterElementRow>
    //   </ContainerGlobal>
    // </FooterElement>
  )
}
export default Footer
