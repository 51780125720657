import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { CustomImage } from "../../Image"

interface FacebookProps {}

const Facebook: React.FC<FacebookProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "facebook.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      alt="Follow Best for your feet on Facebook"
      maxWidth="30px"
      maxHeight="30px"
      image={imageSharp}
    />
  )
}
export default Facebook
