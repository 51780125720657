import React, { useState } from "react"
import { CloseIcon, SearchIcon } from ".."
import {
  Canvas,
  Circle,
  HeaderDesktopNav,
  HeaderNavButton,
  HeaderNavButtonContainer,
  HeaderNavButtonIcon,
  HeaderSearch,
} from "../../../../elements"
import { CustomLink } from "../../../CustomLink"

interface DesktopNavigationProps {}

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({}) => {
  const [isOpenSearch, openSearch] = useState(false)

  const searchIcon = isOpenSearch ? <CloseIcon /> : <SearchIcon />

  return (
    <HeaderDesktopNav>
      <ul>
        <li>
          <div className="tags-list">
            <span>Tags</span>
          </div>
        </li>
        <li>
          <CustomLink href="/guide">Guides</CustomLink>
        </li>
        <li>
          <CustomLink href="/blog">Blog</CustomLink>
        </li>
        <li>
          <CustomLink href="/about-us">About</CustomLink>
        </li>
        <li
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <HeaderSearch>
            <HeaderNavButton onClick={() => openSearch(!isOpenSearch)}>
              <HeaderNavButtonContainer>
                <Circle style={{ height: "40px", width: "40px" }}>
                  <Canvas width="40" height="40" />
                  <Canvas width="40" height="40" />
                </Circle>
                <HeaderNavButtonIcon>{searchIcon}</HeaderNavButtonIcon>
              </HeaderNavButtonContainer>
            </HeaderNavButton>
          </HeaderSearch>
        </li>
      </ul>
    </HeaderDesktopNav>
  )
}
export default DesktopNavigation
