import { graphql, Link, useStaticQuery } from "gatsby"

import React from "react"
import {
  ArticlesItem,
  ArticleElement,
  ArticleCard,
  ArticleCardContent,
  ArticleCardMetadata,
  ArticleCardMetadataText,
  ArticleCardMetaDescription,
} from "../../elements"
import { CustomImage } from "../Image"
interface ArticleCardComponentProps {
  fixed?: any
  slug?: string
  title?: string
  description?: string
  updatedDate?: string
  timeToRead?: string
  type?: string
}

const ArticleCardComponent: React.FC<ArticleCardComponentProps> = ({
  fixed,
  slug,
  title,
  description,
  updatedDate,
  timeToRead,
  type,
}) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "shore_placeholder.jpg" } }) {
        id
        gatsbyImageData
      }
    }
  `)
  return (
    <ArticlesItem>
      <ArticleElement>
        <ArticleCard>
          <Link to={`/${type}/${slug}`}>
            <CustomImage
              position="static"
              alt={title}
              image={fixed ? fixed : data.imageSharp}
            />
          </Link>
        </ArticleCard>
        <ArticleCardContent>
          <Link to={`/${type}/${slug}`}>
            <h3>{title}</h3>
          </Link>
          <ArticleCardMetadata>
            <ArticleCardMetadataText className="floatLeft">
              <strong>Updated</strong> {updatedDate}
            </ArticleCardMetadataText>
            <ArticleCardMetadataText className="floatRight">
              {timeToRead} min read
            </ArticleCardMetadataText>
          </ArticleCardMetadata>
          <ArticleCardMetaDescription>
            <Link to={`/${type}/${slug}`}>{description}</Link>
          </ArticleCardMetaDescription>
        </ArticleCardContent>
      </ArticleElement>
    </ArticlesItem>
  )
}
export default ArticleCardComponent
