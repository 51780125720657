import styled from "@emotion/styled"

export const HeroElement = styled.section`
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 200px 0px 250px 0px;
  z-index: 1;
  position: relative;
  @media (max-width: 600px) {
    padding: 120px 0px 250px 0px;
  }
`

export const HeroInner = styled.div`
  max-width: 1140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`
export const HeroInnerRow = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
    just-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
`

export const HeroInnerColumn = styled.div`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  @media (min-width: 768px) {
    width: 50%;
  }
`
export const HeroInnerHeading = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  width: 100%;
  position: relative;
`

export const HeroHeading = styled.h1`
  font-weight: normal;
  font-size: 3rem;
  line-height: 1.2;
`
export const HeroHeading2 = styled.h2`
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.2;
`

export const HeroElementCover = styled.div`
  width: 100%;
  position: relative;
`
export const HeroFormContainer = styled.div`
  margin: 30px 0px 0px 0px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
`

export const HeroFormInner = styled.div`
  padding: 6px;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 2px 4px 12px 0px rgb(0 0 0 / 19%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  overflow: hidden;
  border: 0 solid transparent;
  min-height: 50px;
`

export const HeroFormInput = styled.input`
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  min-width: 0;
  font-size: 15px;
  -webkit-appearance: none;
  flex-basis: 100%;
  padding-left: calc(40px / 3);
  padding-right: calc(40px / 3);
  font-size: 20px;
`
export const HeroImageWrapper = styled.div`
  align-content: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`
