import styled from "@emotion/styled"

export const PostContent = styled.div`
  font-family: ${props => props.theme.fonts.article};
  text-align: left;

  & a {
    color: #d01414;
    text-decoration: underline;
  }

  & h1 a,
  & h2 a,
  & h3 a,
  & h4 a,
  & h5 a,
  & h6 a {
    text-decoration: none;
    color: #d01414;
    &:hover {
      text-decoration: underline;
    }
  }

  & strong {
    color: ${props => props.theme.colors.headingTextColor};
  }

  & ol {
    padding-left: 1em;
    margin-top: 1em;
    line-height: 2em;
  }

  & ul {
    padding-left: 1em;
    margin-top: 1em;
    line-height: 2em;

    list-style-type: disc;
  }

  & ul li {
    margin-top: 0.53em;
  }

  & ol li {
    margin-top: 0.53em;
  }

  & ul.compact li {
    margin-top: 0.26em;
  }

  & img,
  iframe {
    display: block;
    width: 100%;
    margin: 1em auto 0 auto;
  }

  & img.compact {
    display: inline-block;
  }

  & hr {
    border: 1px solid;
    margin: 1.5789em 2.6316em;
  }
  & blockquote {
    & p {
      position: relative;
      background-color: #efefef;
      padding: 0.53em 0.53em 0.53em 0.7895em;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border-left: 4px solid #3f51b5;
    }
  }
`

export const PostArticle = styled.article`
  & h1 {
    margin: 1.5em 0px;
  }

  & h2 {
    font-size: 1.5em;
    margin: 1.3157em 0 1em 0;

    @media (max-width: 600px) {
      font-size: 1.2em;
    }
  }

  & h3 {
    font-size: 1.3em;
    margin-top: 1em;
  }

  & h4 {
    font-size: 1em;
    margin-top: 1em;
  }

  & p {
    margin-top: 1em;
    line-height: 2em;
    font-size: 0.95em;
  }

  & li {
    font-size: 0.95em;
  }
`
