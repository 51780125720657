import styled from "@emotion/styled"

export const DisplayFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const DisplayFlexRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const DisplayFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5em;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const FourColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 9px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
