import styled from "@emotion/styled"

export const SpecificationsBox = styled.div`
  background: #f9f9f9;
  border: 1px solid #959595;
  margin: 20px 0;
  padding: 40px;

  & ul {
    list-style: round;
    margin: 0;
    color: black;
    padding-left: 20px;
  }
`
