import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import {
  ArticlesGridContainer,
  DisplayFlexColumn,
  MainContainer,
  PageSectionPronounced,
  PageSectionPronouncedHeader,
  PopularShoeGuidesHeading,
  SectionSpacer,
} from "../../elements"
import { ArticleCardComponent } from "../ArticleCard"

interface PopularShoeGuidesProps {}

const PopularShoeGuides: React.FC<PopularShoeGuidesProps> = ({}) => {
  const { guides } = useStaticQuery(graphql`
    query {
      guides: allMdx(
        limit: 30
        filter: {
          frontmatter: { published: { eq: true }, type: { eq: "guide" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            slug
            timeToRead
            frontmatter {
              description
              title
              type
              date(formatString: "MMMM DD, YYYY")
              featureImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <PageSectionPronounced>
      <MainContainer>
        <DisplayFlexColumn>
          <PageSectionPronouncedHeader>
            <h2>Guides for you</h2>
          </PageSectionPronouncedHeader>
          <SectionSpacer marginTop="1em" />
          <ArticlesGridContainer>
            {guides.edges.map(guide => (
              <ArticleCardComponent
                type={guide.node.frontmatter.type}
                key={guide.node.id}
                title={guide.node.frontmatter.title}
                description={guide.node.frontmatter.description}
                updatedDate={guide.node.frontmatter.date}
                slug={guide.node.slug}
                timeToRead={guide.node.timeToRead}
                fixed={guide.node.frontmatter.featureImage.childImageSharp}
              />
            ))}
          </ArticlesGridContainer>
        </DisplayFlexColumn>
      </MainContainer>
    </PageSectionPronounced>
  )
}
export default PopularShoeGuides
