import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

interface SeoProps {
  description?: string
  lang?: string
  image?: any
  meta?: []
  title?: string
  pathname?: string
  keywords?: []
  schemaMarkup?: any
  type?: string
  article_category?: string
}

const Seo: React.FC<SeoProps> = ({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  keywords,
  type,
  schemaMarkup,
  article_category,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  const pageKeywords = keywords
    ? keywords.join(",")
    : site.siteMetadata.keywords.join(",")

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        { name: `msapplication-tap-highlight`, content: `no` },
        {
          name: `https://www.facebook.com/bestforyourfeet/`,
          content: `article:publisher`,
        },

        {
          name: `description`,
          content: metaDescription,
        },
        { name: `title`, content: title },
        {
          name: `keywords`,
          content: pageKeywords,
        },
        {
          property: `og:url`,
          content: canonical ? canonical : site.siteMetadata.siteUrl,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.name,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `${type ? type : `website`}`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    >
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  )
}

export default Seo

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
