import styled from "@emotion/styled"

export const PopularShoeGuidesElement = styled.section`
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 00px;
  margin-bottom: 0px;
  padding: 70px 0px 50px 0px;
  position: relative;
  background-color: #d2e3fc;
`
export const PopularShoeGuidesInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const PopularShoeGuidesHeading = styled.div`
  margin: 0px 0px 60px 0px;
  text-align: center;
  -webkit-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
  width: 100%;
  & h2 {
    font-size: 40px;
    font-weight: normal;
  }
`
