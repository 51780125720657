import React from "react"
import {
  HeaderMenuButton,
  HeaderMenuButtonContent,
  Circle,
  Canvas,
  HeaderMenuButtonClose,
  HeaderMenuButtonText,
} from "../../../../elements"

interface MenuCloseIconProps {
  onClick?: () => void
}

const MenuCloseIcon: React.FC<MenuCloseIconProps> = ({ onClick }) => {
  return (
    <HeaderMenuButton onClick={onClick}>
      <HeaderMenuButtonContent>
        <Circle
          style={{
            height: "40px",
            width: "40px",
            top: "20px",
            left: "20px",
          }}
        >
          <Canvas width="40" height="40" />
          <Canvas width="40" height="40" />
        </Circle>
        <HeaderMenuButtonClose>
          <li />
          <li style={{ zIndex: 0, transform: "matrix(0, 0, 0, 1, 0, 0)" }} />
          <li
            style={{
              transformOrigin: "center center 0px",
              transform: "matrix(0.7071, 0.7071, -0.7071, 0.7071, -5, 0)",
            }}
          />
        </HeaderMenuButtonClose>
      </HeaderMenuButtonContent>
      <HeaderMenuButtonText>Close</HeaderMenuButtonText>
    </HeaderMenuButton>
  )
}
export default MenuCloseIcon
