import React from "react"
import {
  HeaderMenuButton,
  HeaderMenuButtonContent,
  Circle,
  Canvas,
  HeaderMenuButtonHum,
  HeaderMenuButtonText,
} from "../../../../elements"

interface MenuIconProps {
  onClick?: () => void
}

const MenuIcon: React.FC<MenuIconProps> = ({ onClick }) => {
  return (
    <HeaderMenuButton onClick={onClick}>
      <HeaderMenuButtonContent>
        <Circle
          style={{
            height: "40px",
            width: "40px",
            top: "20px",
            left: "20px",
          }}
        >
          <Canvas width="40" height="40" />
          <Canvas width="40" height="40" />
        </Circle>
        <HeaderMenuButtonHum>
          <li />
          <li />
          <li />
        </HeaderMenuButtonHum>
      </HeaderMenuButtonContent>
      <HeaderMenuButtonText>Menu</HeaderMenuButtonText>
    </HeaderMenuButton>
  )
}
export default MenuIcon
