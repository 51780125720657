import styled from "@emotion/styled"

export const FeaturedBy = styled.div`
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
  margin: 2px 10px 20px 10px;
  padding: 40px 0px 40px 0px;
  background-color: #ffffff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 15%);
    transition: transform 0.3s ease 0s;
  }
`
