import styled from "@emotion/styled"

export const SelectionsListContainer = styled.div`
  max-width: calc(100% - 16px);
  margin: 1em auto;
  padding: 0;
  margin-left: 16px;
  @media (max-width: 600px) {
    margin-left: 0em;
    max-width: 100%;
  }
`
export const SelectionsListInner = styled.div`
  border-spacing: 0;
  font-family: inherit, sans-serif;
  width: 100%;
  border: none !important;
  border-collapse: separate;
`

export const SelectionsListHeader = styled.div`
  background-color: #1a7ca9 !important;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 0 7px;
  text-align: left;
  border-bottom: 0 solid #000 !important;
`

export const SelectionsListHeaderInner = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const SelectionsListHeaderHeading = styled.div`
  padding: 7px;
  letter-spacing: 1px;
  padding-left: 20px;
  text-align: center;
  font-weight: 500;
  display: table-cell;
  text-transform: uppercase;
  font-size: 15px;
  width: 35%;
  border: none !important;
`

export const SelectionsListItem = styled.div`
  margin: 0;
  padding: 0 0 10px 0;
  border: 1px solid #dcdcdc;
  border-top: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`
export const SelectionsListItemLabel = styled.div`
  display: flex;
  order: 1;
  color: #444;
  line-height: 18px;
  text-transform: uppercase;

  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 0;
  text-align: left;
  margin: 0;
  min-width: 280px;
  height: auto;
  width: 100%;
  box-sizing: border-box;

  & a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  & ol {
    margin-top: 0em;
    & li {
      @media (max-width: 600px) {
        font-size: 0.88em;
      }
    }
  }

  font-size: 1em;
  font-weight: 600;
  vertical-align: middle;
  text-align: left;
  padding: 10px 0 10px 15px;
  line-height: 1.3em;
`

export const SelectionsListItemImage = styled.div`
  text-align: center;
  box-sizing: border-box;
  width: 30%;
  vertical-align: middle;
  padding: 0px !important;
  display: flex;
  order: 3;
  flex-direction: column;
  @media (max-width: 600px) {
    width: 100%;
  }
`
export const SelectionsListItemSpecs = styled.div`
  order: 3;
  flex-wrap: wrap;
  box-sizing: border-box;
  line-height: 1.5em;
  border-right: none !important;
  width: 40%;
  vertical-align: middle;
  padding: 5px;
  margin: 0;
  font-family: inherit;
  font-weight: bold;

  @media (min-width: 600px) {
    width: 38%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & ul,
  ol {
    padding-left: 8px;
    margin-left: 10px;
    list-style-type: round;
    margin: 1.5em 0 1.571em 2.5em;

    & li {
      color: #3a3a3a;
      margin-bottom: 5px !important;
      line-height: 1.3em;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
    }
  }
`
export const SelectionsListItemButton = styled.div`
  @media (min-width: 600px) {
    width: 32%;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  padding-left: 15px;
  padding-right: 15px;

  box-sizing: border-box;
  width: 30%;
  vertical-align: middle;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  flex-wrap: wrap;
  order: 4;
`
export const TopSelectionInList = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  order: 2;
  margin: 0 0;
`
export const TopSelectionInListLabel = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: normal;
  border: 0;
  color: #fff;
  position: relative;
  left: -10px;
  top: -10px;
  text-transform: uppercase;
  background: #f78a37;
  display: flex;
  text-align: center;
  font-weight: 700;
  font-size: 14px !important;
  word-break: keep-all;
  z-index: 999;
  padding: 4px 30px;
  line-height: 2.7;

  &::before {
    border-bottom: 10px solid transparent;
    border-right: 10px solid #714010;
    display: block;
    width: 10px;
    height: 0;
    position: absolute;
    bottom: -10px;
    left: 0px;
    content: "";
  }
`
