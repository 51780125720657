import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

interface CustomImageProps {
  image: any
  className?: string
  imgClassName?: string
  maxHeight?: string
  maxWidth?: string
  padding?: string
  alt?: string
  position?: "static" | "relative" | "absolute" | undefined
}

const CustomImage: React.FC<CustomImageProps> = ({
  image,
  className,
  imgClassName,
  maxHeight,
  maxWidth,
  padding,
  alt,
  position,
}) => {
  const ImageSrc = getImage(image)
  return (
    <GatsbyImage
      style={{
        maxHeight: `${maxHeight}`,
        maxWidth: `${maxWidth}`,
        padding: `${padding}`,
        position: `${position}`,
      }}
      className={className}
      imgClassName={imgClassName}
      imgStyle={{}}
      image={ImageSrc}
      alt={alt}
    />
  )
}
export default CustomImage
