import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { CustomImage } from "../Image"

interface RunRepeatProps {}

const RunRepeat: React.FC<RunRepeatProps> = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "runrepeater.jpg" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      maxHeight="auto"
      maxWidth="100%"
      image={data.imageSharp}
      alt="Featured on Run Repeat"
    />
  )
}
export default RunRepeat
