import styled from "@emotion/styled"

export const CurrentPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: inherit;
  padding: 10px 0px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const CurrentPriceLink = styled.a`
  background-color: #e74c3c;
  color: #fff !important;
  border: 0 solid #c20000;
  border-bottom: 4px solid #9b3c2f;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif !important;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  margin: 20px 0 0 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.1s linear;
  display: block;
  box-shadow: 0 2px 1px 0 rgb(0 0 0 / 30%);

  @media (max-width: 600px) {
    margin: 0em;
  }

  &:hover {
    background-color: #ba1200;
    border-top: 2px solid #9b3c2f;
    border-bottom: 2px solid #9b3c2f;
  }
`
