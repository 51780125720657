import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import CustomImage from "../../Image/CustomImage"

interface SearchIconProps {}

const SearchIcon: React.FC<SearchIconProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(
        fixed: { originalName: { eq: "search-interface-symbol.png" } }
      ) {
        gatsbyImageData
      }
    }
  `)
  return <CustomImage position="static" image={imageSharp} />
}
export default SearchIcon
