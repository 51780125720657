import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import CustomImage from "../../Image/CustomImage"

interface CloseIconProps {}

const CloseIcon: React.FC<CloseIconProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "cancel.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return <CustomImage position="static" image={imageSharp} />
}
export default CloseIcon
