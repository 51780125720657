import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import {
  Canvas,
  Circle,
  HeaderMobileNav,
  HeaderNavButton,
  HeaderNavButtonContainer,
  HeaderNavButtonIcon,
  HeaderSearch,
  MainContainer,
  MainHeader,
  MainHeaderDesktop,
  MainHeaderMobile,
  MainHeaderWrapper,
  MobileHeaderSearchInput,
} from "../../elements"
import { pluckTags } from "../../utils"
import {
  CloseIcon,
  DesktopNavigation,
  Logo,
  MenuCloseIcon,
  MenuIcon,
  MobileNavigation,
  SearchIcon,
} from "./components"

interface HeaderProps {
  theme?: any
}

const Header: React.FC<HeaderProps> = ({}) => {
  const [isOpenMenu, openMenu] = useState(false)
  const [isOpenSearch, openSearch] = useState(false)

  const { BlogPosts } = useStaticQuery(graphql`
    query {
      BlogPosts: allMdx(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `)

  const edges = BlogPosts.edges
  const tags = pluckTags(edges)

  const mobileNavigation = isOpenMenu ? <MobileNavigation tags={tags} /> : null

  const searchIcon = isOpenSearch ? <CloseIcon /> : <SearchIcon />

  const searchInput = isOpenSearch ? (
    <div style={{ position: "absolute" }}>
      <MobileHeaderSearchInput
        type="text"
        name="search"
        placeholder="Search"
        autoComplete="off"
      />
    </div>
  ) : null

  return (
    <MainHeader>
      <MainHeaderWrapper>
        <MainContainer>
          <MainHeaderDesktop>
            <Logo />
            <DesktopNavigation />
          </MainHeaderDesktop>
          <MainHeaderMobile>
            {isOpenMenu ? (
              <MenuCloseIcon onClick={() => openMenu(false)} />
            ) : (
              <MenuIcon onClick={() => openMenu(true)} />
            )}

            <HeaderSearch>
              {searchInput}
              <HeaderNavButton onClick={() => openSearch(!isOpenSearch)}>
                <HeaderNavButtonContainer>
                  <Circle style={{ height: "40px", width: "40px" }}>
                    <Canvas width="40" height="40" />
                    <Canvas width="40" height="40" />
                  </Circle>
                  <HeaderNavButtonIcon>{searchIcon}</HeaderNavButtonIcon>
                </HeaderNavButtonContainer>
              </HeaderNavButton>
            </HeaderSearch>
          </MainHeaderMobile>
        </MainContainer>

        {mobileNavigation}
      </MainHeaderWrapper>
    </MainHeader>
  )
}
export default Header
