import React from "react"
import {
  MainContainer,
  HeaderMobileNav,
  MobileNavigationLabel,
  MobileNavigationTagOpener,
  MobileNavigationTagList,
} from "../../../../elements"
import { capitalize } from "../../../../utils"
import { CustomLink } from "../../../CustomLink"

interface MobileNavigationProps {
  tags?: any[]
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({ tags }) => {
  const tagsList = tags.map(tag => (
    <li key={tag}>
      <CustomLink href={`/category/${tag.toLowerCase()}`}>
        {capitalize(tag)} Shoes
      </CustomLink>
    </li>
  ))
  return (
    <MainContainer>
      <HeaderMobileNav>
        <MobileNavigationLabel>
          <MobileNavigationTagOpener>
            <div>Tags</div>
            <div></div>
          </MobileNavigationTagOpener>
          <MobileNavigationTagList>{tagsList}</MobileNavigationTagList>
        </MobileNavigationLabel>
        <MobileNavigationLabel>
          <CustomLink href="/guide">Guides</CustomLink>
        </MobileNavigationLabel>
        <MobileNavigationLabel>
          <CustomLink href="/blog">Blog</CustomLink>
        </MobileNavigationLabel>
        <MobileNavigationLabel>
          <CustomLink href="/disclaimer">Disclaimer</CustomLink>
        </MobileNavigationLabel>
        <MobileNavigationLabel>
          <CustomLink href="/about-us">About Us</CustomLink>
        </MobileNavigationLabel>
        <MobileNavigationLabel>
          <CustomLink href="/privacy">Privacy Policy</CustomLink>
        </MobileNavigationLabel>
      </HeaderMobileNav>
    </MainContainer>
  )
}
export default MobileNavigation
