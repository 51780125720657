import React from "react"
import { TopSelectionInList, TopSelectionInListLabel } from "../../elements"

interface SelectionListTopSelectionProps {}

const SelectionListTopSelection: React.FC<SelectionListTopSelectionProps> =
  ({}) => {
    return (
      <TopSelectionInList>
        <TopSelectionInListLabel>Top Selection</TopSelectionInListLabel>
      </TopSelectionInList>
    )
  }
export default SelectionListTopSelection
