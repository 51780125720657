import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { CustomImage } from "../../Image"

interface PinterestProps {}

const Pinterest: React.FC<PinterestProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "pinterest.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      alt="Follow Best for your feet on Pinterest"
      maxWidth="30px"
      maxHeight="30px"
      image={imageSharp}
    />
  )
}
export default Pinterest
