import React from "react"

import { SpecificationsBox } from "../../elements"

interface SpecificationsProps {}

const Specifications: React.FC<SpecificationsProps> = ({ children }) => {
  return <SpecificationsBox>{children}</SpecificationsBox>
}
export default Specifications
