import styled from "@emotion/styled"

export const MainContainer = styled.div`
  max-width: 884px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const PageContainer = styled.div`
  padding-top: 30px;

  @media (max-width: 600px) {
    padding-top: 20px;
  }
`

export const SectionSpacer = styled.div`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`

export const ContainerElement = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1201px) {
    max-width: 808px;
  }
  @media (min-width: 993px) .ast-container {
    max-width: 808px;
  }
  .ast-container {
    max-width: 808px;
  }
  .ast-container {
    max-width: 100%;
  }
`

export const ContainerGlobal = styled.div`
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`

export const ContainerRowGlobal = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`
export const GlobalColumnWrapper = styled.div`
  padding: 10px;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
`

export const GlobalWidgetWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
`

export const GlobalHeadingContainer = styled.div`
  text-align: center;
  width: 100%;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
export const WidgetContainer = styled.div`
  margin: 0px 0px 40px 0px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
`
