import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { CustomImage } from "../../Image"

interface InstagramProps {}

const Instagram: React.FC<InstagramProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "instagram.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      alt="Follow Best for your feet on Instagram"
      maxWidth="30px"
      maxHeight="30px"
      image={imageSharp}
    />
  )
}
export default Instagram
