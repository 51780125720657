import styled from "@emotion/styled"

export const PageFooter = styled.footer`
  bottom: 0;
  padding: 0em;
`

export const FooterSocialLinks = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 1em;
  margin-top: 1em;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  & a {
    padding: 1em;
  }
`
export const FooterNavLinks = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.article};

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  & a {
    white-space: nowrap;
    padding: 0.68em 1em;
    transition: 0.4s;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 0.8em;
    color: #000;

    &:hover {
      text-decoration: underline;
      color: #d01414;
    }
  }
`

export const FooterLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0em;
`

export const FooterCopyRight = styled.div`
  margin: 1em 0em;
  font-family: ${props => props.theme.fonts.article};
  text-align: center;
  & p {
    font-size: 0.8em;
  }
`

export const FooterDisclaimer = styled.div`
  width: 100%;
  padding: 1em;
  text-align: "center";
  & p {
    font-size: 0.8em;

    & strong {
      color: #d01414;
      font-size: 0.95em;
    }
  }
`
