import React from "react"
import {
  TopSelectionContainer,
  TopSelectionInner,
  TopSelectionItems,
  TopSelectionLabel,
} from "../../elements"

interface TopSelectionProps {}

const TopSelection: React.FC<TopSelectionProps> = ({ children }) => {
  return (
    <TopSelectionContainer>
      <TopSelectionInner>
        <TopSelectionItems>
          <TopSelectionLabel>Our Top Selection</TopSelectionLabel>
          {children}
        </TopSelectionItems>
      </TopSelectionInner>
    </TopSelectionContainer>
  )
}
export default TopSelection
