import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { CustomImage } from "../../Image"
interface TikTokProps {}

const TikTok: React.FC<TikTokProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "tiktok.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      alt="Follow Best for your feet on TikTok"
      maxWidth="30px"
      maxHeight="30px"
      image={imageSharp}
    />
  )
}
export default TikTok
