import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { CustomImage } from "../../Image"

interface TwitterProps {}

const Twitter: React.FC<TwitterProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "twitter.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      alt="Follow Best for your feet on Twitter"
      maxWidth="30px"
      maxHeight="30px"
      image={imageSharp}
    />
  )
}
export default Twitter
