import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { CustomLink } from "../../CustomLink"

interface LogoProps {}

const Logo: React.FC<LogoProps> = ({}) => {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(
        fixed: { originalName: { eq: "best_for_your_feet_logo.png" } }
      ) {
        gatsbyImageData
      }
    }
  `)
  const Image = getImage(imageSharp)
  return (
    <CustomLink href="/">
      <GatsbyImage
        style={{
          maxHeight: "50px",
          maxWidth: "226px",
          padding: "0.5rem 0px 5px",
        }}
        className="logo"
        imgClassName="logo"
        imgStyle={{}}
        image={Image}
        alt={`Best for your feet logo`}
      />
    </CustomLink>
  )
}
export default Logo
