import styled from "@emotion/styled"

export const TopSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 16px;
  position: relative;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`
export const TopSelectionInner = styled.div`
  border: 3px solid #1a7ca9;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0;
  text-decoration: none;
  padding: 20px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding-top: 2.85em;
  }

  & p {
    width: calc(100% - 100px);
    margin-left: auto;
    margin-right: auto;
    color: #3a3a3a;
    font-family: inherit;
    vertical-align: middle !important;
    display: table;
    font-weight: normal;
    text-align: center;

    @media (max-width: 600px) {
      width: calc(100% - 10px);
      font-size: 0.9em;
    }
  }
`

export const TopSelectionItems = styled.div`
  color: #7ea43c;
  color: #7ea43c;
  align-items: center;
  margin: auto;
  font-weight: bold;
  order: 0;
  text-align: center;
  width: 65%;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const TopSelectionLabel = styled.div`
  color: #fff;
  float: left;
  font-weight: bold;
  font-size: 14px;
  max-width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 30px;
  position: absolute;
  left: -10px;
  margin-left: 0;
  top: 20px;
  line-height: 2.7;
  text-transform: uppercase;
  background: #f78a37;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  padding: 4px 30px;
  clear: both;

  @media (max-width: 600px) {
    padding: 4px 1em;
    max-width: 100%;
  }

  &::before {
    display: block;
    width: 20px;
    height: 0;
    position: absolute;
    bottom: -10px;
    left: -10px;
    content: "";
    border-bottom: 10px solid transparent;
    border-right: 10px solid #714010;
  }
`
