import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { CustomImage } from "../Image"

interface BuzzFeedProps {}

const BuzzFeed: React.FC<BuzzFeedProps> = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "buzzfeed.png" } }) {
        gatsbyImageData
      }
    }
  `)
  return (
    <CustomImage
      maxHeight="auto"
      maxWidth="100%"
      image={data.imageSharp}
      alt="Featured on BuzzFeed"
    />
  )
}
export default BuzzFeed
