import React from "react"
import {
  SelectionsListContainer,
  SelectionsListHeader,
  SelectionsListHeaderHeading,
  SelectionsListHeaderInner,
  SelectionsListInner,
} from "../../elements"

interface SelectionsListProps {}

const SelectionsList: React.FC<SelectionsListProps> = ({ children }) => {
  return (
    <SelectionsListContainer>
      <SelectionsListInner>
        <SelectionsListHeader>
          <SelectionsListHeaderInner>
            <SelectionsListHeaderHeading>Product</SelectionsListHeaderHeading>
            <SelectionsListHeaderHeading>Specs</SelectionsListHeaderHeading>
            <SelectionsListHeaderHeading>Price</SelectionsListHeaderHeading>
          </SelectionsListHeaderInner>
        </SelectionsListHeader>

        {children}
      </SelectionsListInner>
    </SelectionsListContainer>
  )
}
export default SelectionsList
