import styled from "@emotion/styled"

export const OurMissions = styled.div`
  font-family: ${props => props.theme.fonts.article};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    font-size: 1em;
  }

  & p {
    font-size: 0.9em;
  }
`
