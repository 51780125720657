import { Link } from "gatsby"
import React from "react"
import {
  DisplayFlexColumn,
  MainContainer,
  OurMissions,
  PageSectionNormal,
  PageSectionNormalHeader,
  SectionSpacer,
  ThreeColumnGrid,
} from "../../elements"

interface OurMissionProps {}

const OurMission: React.FC<OurMissionProps> = ({}) => {
  return (
    <PageSectionNormal>
      <MainContainer>
        <DisplayFlexColumn>
          <PageSectionNormalHeader>
            <h2>
              <Link to="/">Bestforyourfeet.com</Link> is a major footwear review
              website created By footwear enthusiasts, For footwear enthusiasts.
            </h2>
          </PageSectionNormalHeader>
          <SectionSpacer marginTop="5em" />
          <ThreeColumnGrid>
            <OurMissions>
              <h2>Who we are</h2>
              <p>
                We're a tiny, close-knit crew of dedicated bloggers, analysts,
                and footwear aficionados that share a knack for making things as
                simple as possible.
              </p>
            </OurMissions>
            <OurMissions>
              <h2>Who we do</h2>
              <p>
                Our goal is to assist you in finding your next pair of shoes.
                Since whats best for your feet is also whats best for your first
                impression.
              </p>
            </OurMissions>
            <OurMissions>
              <h2>How we doit</h2>
              <p>
                We evaluate numerous  popular and well - known shoe brands
                available based on our unbiased analysis of research and
                reviews.
              </p>
            </OurMissions>
          </ThreeColumnGrid>
        </DisplayFlexColumn>
      </MainContainer>
    </PageSectionNormal>
  )
}
export default OurMission
