import styled from "@emotion/styled"

export const ProsConsContainer = styled.div`
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  font-family: inherit;
  font-size: 18px;
  border: 1px solid #959595;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & h4 {
    color: #fff;
    padding: 15px;
    margin-bottom: 0;
    font-weight: bold;
    margin-top: 0;
  }

  & ul {
    list-style-type: none;
    margin: 0em;
  }
  & li {
    padding: 10px;
    font-size: 18px;
    display: flex;
  }
`
export const ProsContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-basis: 100%;
  }

  & h4 {
    background: #69be28;
    width: 100%;
  }

  & ul {
    & li {
      &:before {
        content: "✓";
        color: #69be28;
        margin-right: 8px;
        font-weight: bold;
      }
    }
  }
`
export const ConsContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-basis: 100%;
  }

  & h4 {
    background: #f78a37;
  }

  & ul {
    & li {
      &:before {
        content: "x";
        color: #f78a37;
        margin-right: 8px;
        font-weight: bold;
      }
    }
  }
`
